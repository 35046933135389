<template src="./education.html"></template>

<script>
import Http from '@/shared/http-config'
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect'
import ImageRotate from '@/shared/ImageRotate'

export default {
  name: "Education",
  components: { Multiselect, ImageRotate },
  data: function () {
    return {
      new_picture: null,
      courses: [],
      picture_visibility_model: []
    }
  },
  computed: {
    package_variations () {
      return this.$store.getters['customers/get']('package_variations');
    },
    ...mapFields('customers', {
      customer: 'customer',
      education: 'education',
      education_start: 'education.start',
      education_end: 'education.end',
      education_picture: 'education.picture',
      picture_visibility: 'education.picture_visibility',
      education_passed_talk: 'education.passed_talk',
      education_tests_theory: 'education.tests.theory',
      education_tests_praxis: 'education.tests.praxis',
    }),
  },
  watch: {
    $route (to, from) {
      this.picture_visibility_model = [];
      if(this.education.picture_visibility & 1){
        this.picture_visibility_model.push(1);
      }
      if(this.education.picture_visibility & 2){
        this.picture_visibility_model.push(2);
      }
    }
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
  created () {
    let self = this;
    Http.request('GET', '/courses?customer_id=' + self.$route.params.id).then((courses) => {
      self.courses = courses.data;
    });

    if(self.education.picture_visibility & 1){
      self.picture_visibility_model.push(1);
    }
    if(self.education.picture_visibility & 2){
      self.picture_visibility_model.push(2);
    }
  },
  methods: {
    onFileChange(e) {
      this.new_picture = (e.dataTransfer?.files[0] ? e.dataTransfer?.files[0] : e.target.files[0]);
      this.education_picture = URL.createObjectURL(this.new_picture);
    },
    async save () {
      let self = this;
      let isValid = await self.$refs.educationObserver.validate();
      if (!isValid) {
        self.$bvToast.toast('Bitte korrigiere die markierten Felder', {
          title: 'Pflichtfelder',
          variant: 'warning',
          autoHideDelay: 5000
        });
        return false;
      }

      if(self.education.tests.theory === '0000-00-00'){
        self.education.tests.theory = null;
      }
      if(self.education.tests.praxis === '0000-00-00'){
        self.education.tests.praxis = null;
      }

      // check if img was rotated
      if(typeof(self.$refs.imageRotate) !== "undefined"){
        let rotatedImage = await self.$refs.imageRotate.getImage(true);
        if(rotatedImage){
          self.new_picture = rotatedImage;
        }
      }

      let formData = new FormData();
      if(self.new_picture !== null) {
        formData.append('new_picture', self.new_picture);
      }
      self.picture_visibility = self.picture_visibility_model?.reduce((pv, cv) => pv + cv, 0) ?? 0;
      formData.appendRecursive(self.education);

      Http.request('POST', '/customers/' + self.$route.params.id + '/educations/' + self.$route.params.education, formData).then(function (results) {
        self.education = results.data;
        self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + self.$route.params.education + '/profile');
      });
    },
  }
}
</script>

<style scoped></style>
